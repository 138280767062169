/**
 * Global
 * -------------------------
 */

body {
    min-width: 320px;
    overflow-x: hidden;
}

p {
    margin: 0;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: 400;
    margin: 0;
}

h1, .h1 {
    color: $headline-color;
    font-size: rem-calc(24);
    line-height: rem-calc(31);

    @include media-breakpoint-only(lg) {
        font-size: rem-calc(36);
        line-height: rem-calc(46);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(43);
        line-height: rem-calc(56);
    }
}

h2, .h2 {
    font-size: rem-calc(24);
    line-height: rem-calc(31);

    @include media-breakpoint-only(lg) {
        font-size: rem-calc(28);
        line-height: rem-calc(32);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(35);
        line-height: rem-calc(50);
    }
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-size: rem-calc(20);
    line-height: rem-calc(28);

    @include media-breakpoint-up(lg) {
        letter-spacing: rem-calc(1);
        font-size: rem-calc(25);
        line-height: rem-calc(32);
    }
}

.title {
    font-size: rem-calc(19);
    font-weight: 700;
    line-height: rem-calc(27);
    letter-spacing: rem-calc(1);
    text-align: center;
    text-transform: uppercase;
}

p, .copy-text,
ul, ol {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
}

.tiny-text {
    font-size: rem-calc(12);
    line-height: rem-calc(16);
}

figure {
    margin: 0;

    img {
        @include img-fluid();
    }

    figcaption {
        @extend .tiny-text;
        margin-top: rem-calc(5);
    }

    &.rounded img {
        border-radius: 50%;
    }
}

.checklist {
    list-style: none;
    padding: 0;

    li {
        @include icon('icon-checklist.svg', 40, 24, '0 0');
        padding: 0 0 rem-calc(10) rem-calc(40);

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.social-media-icon {
    @include text-hide();
    @include rem-size(25);
    border-radius: 50%;
    display: inline-block;
    position: relative;

    &:after {
        @include animate(opacity);
        @include rem-size(25);
        background-color: #fff !important;
        border-radius: 50%;
        content: '';
        display: block;
        left: 0;
        opacity: 0;
        top: 0;
        position: absolute;
    }

    @include hover-focus() {
        &:after {
            opacity: 1;
        }
    }

    @each $type in blog facebook twitter youtube {
        &--#{$type} {
            @include icon('icon-#{$type}.svg', 0, 0, center, $white);

            &:after {
                @include icon('icon-#{$type}-colored.svg', 0, 0, center, $white);
            }
        }
    }
}

.share-icon {
    @extend .social-media-icon;

    @each $type in facebook google-plus mail twitter {
        &--#{$type} {
            @include icon('icon-#{$type}.svg', 0, 0, center, $white);

            &.share-icon--white {
                @include icon('icon-#{$type}-white.svg', 0, 0, center);

                @include media-breakpoint-down(xs) {
                    background-color: $black;
                }
            }

            &:after {
                @include icon('icon-#{$type}-colored.svg', 0, 0, center, $white);
            }
        }
    }
}

[data-type="placeholder"] {
    margin: 0 auto;
    max-width: calc(#{rem-calc(640)} + 10vw);
    padding: 0 5vw;
}

.iframe-wrapper {
    overflow: hidden;
    position: relative;

    &::before {
        display: block;
        content: '';
        padding-top: percentage(9/16);
    }

    iframe,
    video,
    [data-type="placeholder"] {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    [data-type="placeholder"] {
        margin: 0;
        padding: 0;
        max-width: 100% !important;
        height: 100% !important;
    }

    &--4by3::before {
        padding-top: percentage(3/4);
    }
}

nav ul,
ul.incognito {
    @extend %unstyled-list;
}

a.incognito {
    @extend %unstyled-link;
}

.content {
    padding-top: rem-calc(50);

    > .below-header:first-child {
        margin-top: rem-calc(-50);
    }

    a:not(.button):not(.share-icon):not(.ce-stage__scroll-down) {
        @include animate(color background);
        border-bottom: rem-calc(2) solid $link-color;

        @include hover-focus() {
            background: $link-color;
            border-color: $link-color;
        }
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem-calc(80);

        > .below-header:first-child {
            margin-top: rem-calc(-80);
        }
    }
}

.error-messages {
    @extend %unstyled-list;
    background: $white;
    color: $brand-secondary;
    margin: 0 0 rem-calc(24);
    padding: rem-calc(10);

    &--dark {
        background: $black;
        color: $white;
    }
}

.spam-protection {
    &--at:before {
        content: '@';
    }

    &--dot:before {
        content: '.';
    }

    &__inner {
        display: inline-block;
        overflow: hidden;
        width: 0;
    }
}
