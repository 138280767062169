/**
 * Quote
 * -------------------------
 */

.ce-quote {
    padding: rem-calc(100) 0;
    position: relative;
    text-align: center;

    &:before {
        @include icon('icon-quote.svg', 0, 0, center);
        content: '';
        display: block;
        height: 50%;
        position: absolute;
        top: rem-calc(60);
        width: 100%;
    }

    &__quote {
        font-size: rem-calc(24);
        margin: 0 auto;
        max-width: rem-calc(1400);
        position: relative;
        width: 85%;
    }

    &__author {
        @extend .tiny-text;
        font-weight: 700;
        left: 50%;
        margin: rem-calc(20) auto 0;
        max-width: rem-calc(450);
        position: absolute;
        transform: translateX(-50%);
        width: 85%;
    }

    @include media-breakpoint-up(md) {
        padding: rem-calc(150) 0;

        &:before {
            top: rem-calc(110);
        }

        &__quote {
            font-size: rem-calc(36);
            width: 70%;
        }

        &__author {
            width: 30%;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem-calc(300) 0;

        &:before {
            top: rem-calc(260);
            height: 33%;
        }
    }
}