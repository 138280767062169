/**
 * Forms and buttons
 * -------------------------
 */

.button {
    @include animate(background);
    @include icon('icon-arrow-right.svg', 16, 14, 'right #{rem-calc(14)} center', $lighter-dark);
    border: none;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family-base;
    font-size: rem-calc(20);
    line-height: rem-calc(26);
    padding: rem-calc(11) rem-calc(38) rem-calc(13) rem-calc(15);

    @include hover-focus() {
        background-color: $lighter-dark-hover;
        color: $white;
    }

    &--transparent {
        background-color: transparent;

        @include hover-focus() {
            background-color: transparent;
        }
    }

    &--lighter {
        background-color: $body-bg;
        background-image: url('#{$image-base-uri}icon-arrow-right-dark.svg');
        color: $dark;

        @include hover-focus() {
            background-color: $light-hover;
            color: $dark;
        }
    }

    &--primary {
        background-color: $brand-secondary;

        @include hover-focus() {
            background-color: $brand-secondary-hover;
        }
    }

    &--arrow-down {
        background-image: url('#{$image-base-uri}icon-arrow-down.svg');

        &.button--lighter {
            background-image: url('#{$image-base-uri}icon-arrow-down-dark.svg');
        }
    }

    &--prev,
    &--next {
        @include rem-size(40);
        background-position: center;
        padding: 0;
    }

    &--prev {
        transform: rotate(180deg);
    }
}

.form {
    input[type=text],
    input[type=number],
    input[type=email],
    input[type=tel],
    select,
    textarea {
        border: none;
        display: block;
        font-family: $font-family-base;
        height: rem-calc(50);
        line-height: rem-calc(35);
        outline: none;
        padding: rem-calc(15) rem-calc(10) 0;
        width: 100%;

        &.parsley-error  {
            background: $white url('#{$image-base-uri}icon-warning.svg') no-repeat top rem-calc(16) right rem-calc(16);
            color: $error-color;
        }
    }

    textarea {
        height: rem-calc(240);
        resize: none;
    }

    input[type=radio],
    input[type=checkbox] {
        display: none;
    }

    &__field-title {
        margin-bottom: rem-calc(24);
    }

    &__field-wrapper {
        margin-bottom: rem-calc(40);
        position: relative;

        &--smaller-margin {
            margin-bottom: rem-calc(30);
        }

        &:not(&--radio):not(&--check) {
            label {
                @include animate(font-size top);
                color: $body-color;
                opacity: 0.6;
                position: absolute;
                top: rem-calc(13);
                left: rem-calc(10);
            }

            input:focus ~ label,
            input.filled ~ label,
            input[placeholder] ~ label,
            select.filled ~ label,
            textarea:focus ~ label,
            textarea.filled ~ label,
            textarea[placeholder] ~ label {
                font-size: rem-calc(12);
                top: rem-calc(7);
            }
        }

        &--radio,
        &--check {
            label {
                @include clearfix();
                display: inline-block;
                margin: 0 rem-calc(20) 0 0;
                vertical-align: top;

                a {
                    color: inherit;
                }

                &:before {
                    @include rem-size(24);
                    background: $white;
                    border: rem-calc(2) solid $border-color;
                    content: '';
                    float: left;
                    margin-right: rem-calc(10);
                }
            }

            input:checked + label:before {
                background: $white url('#{$image-base-uri}icon-checklist.svg') no-repeat rem-calc(2) rem-calc(-2);
            }

            &.form__field-wrapper--full-width {
                margin-bottom: rem-calc(20);

                label {
                    width: 100%;
                    margin: 0 0 rem-calc(20) 0;
                }
            }
        }
    }

    &__button-wrapper {
        padding-top: rem-calc(20);
        text-align: center;

        @include media-breakpoint-up(md) {
            &--bigger-margin {
                padding-top: rem-calc(40);
            }

            &--align-right {
                text-align: right;
            }
        }
    }

    &__note {
        color: transparentize($light, 0.5);
        font-size: rem-calc(12);
        font-weight: bold;
        line-height: rem-calc(19);
        max-width: rem-calc(520);
        padding-top: rem-calc(20);

        @include media-breakpoint-up(md) {
            &--bigger-margin {
                padding-top: rem-calc(40);
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__columns {
            @include make-row();

            .form__button-wrapper,
            .form__note {
                @include make-col-ready();
                @include make-col(1, 2);
            }

            .form__button-wrapper {
                @include make-col-push(1, 2)
            }

            .form__note {
                @include make-col-pull(1, 2)
            }
        }

        &__column {
            @include make-col-ready();
            @include make-col(1, 4);
        }
    }
}

.selectable-tags {
    margin-bottom: rem-calc(-10);
    text-align: center;

    label {
        @include animate(background);
        background: $tags-background;
        color: $white;
        cursor: pointer;
        display: inline-block;
        line-height: rem-calc(30);
        margin: 0 rem-calc(5) rem-calc(10);
        padding: 0 rem-calc(10);

        @include hover-focus() {
            background: $tags-hover-background;
        }
    }

    input {
        display: none;

        &:checked + label {
            background: $tags-active-background;
        }
    }
}

.parsley-errors-list {
    @extend %unstyled-list;
    @extend .tiny-text;
    display: none;
    margin-top: rem-calc(3);

    &.filled {
        display: block;
    }
}