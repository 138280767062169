/**
 * Bridge
 * -------------------------
 */

.ce-bridge {
    background: linear-gradient(0deg, $brand-secondary 0%, darken($brand-secondary, 10) 100%);
    padding: rem-calc(30) 5% rem-calc(45);
    position: relative;
    text-align: center;
    overflow: hidden;

    &:before,
    &:after {
        background: $light;
        bottom: rem-calc(-200);
        content: '';
        display: block;
        height: rem-calc(200);
        left: -25%;
        position: absolute;
        transform: rotate(4deg);
        width: 150%;
    }

    &:after {
        transform: rotate(-4deg);
    }

    h1, .h1 {
        color: $light;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(165);
        padding-top: rem-calc(145);

        &:before,
        &:after {
            transform: rotate(8deg);
        }

        &:after {
            transform: rotate(-8deg);
        }
    }
}
