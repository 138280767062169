/**
 * Vendor
 * --------------------
 */

//
// Bootstrap
//
@import "bootstrap/scss/variables";
@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/images";

//
// Tiny-Slider
//
@import "tiny-slider/src/tiny-slider";

.tns {
    &-outer {
        position: relative;
    }

    &-nav {
        display: flex;
        left: rem-calc(20);
        position: absolute;
        top: rem-calc(20);
        z-index: 10;

        button {
            @include rem-size(10);
            background: $white;
            border: none;
            display: block;
            outline: none;
            opacity: 0.42;
            padding: 0;

            &:not(:first-child) {
                margin-left: rem-calc(12.5);
            }

            &.tns-nav-active {
                opacity: 1;
            }
        }

        @include media-breakpoint-up(lg) {
            left: rem-calc(40);
            top: rem-calc(40);
        }
    }
}