/**
 * Powermail
 * -------------------------
 */

.ce-form {
    background: $brand-secondary;
    color: $white;
    padding: rem-calc(60) rem-calc(16) rem-calc(60);

    h1, .h1 {
        color: inherit;
        margin: 0 0 rem-calc(30);
        text-align: center;
    }

    .form,
    &__confirmation {
        margin: 0 auto;
        max-width: rem-calc(600);
    }

    &--wide {
        padding-left: 5%;
        padding-right: 5%;

        .form,
        &__confirmation {
            max-width: 100%;
        }
    }

    @at-root .ce-form-intro + & {
        padding-top: rem-calc(40);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(150);
        padding-top: rem-calc(150);

        h1, .h1 {
            margin-bottom: rem-calc(70);
        }
    }
}