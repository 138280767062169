/**
 * Header
 * -------------------------
 */

.header {
    @include animate(background);
    background: transparentize($dark, 1);
    height: rem-calc(50);
    left: 0;
    padding: 0 5%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;

    &__logo {
        @extend .text-hide;
        @include icon('logo-trendmanager-white.svg');
        @include rem-size(174, 16);
        float: left;
        display: block;
        margin: rem-calc(17) 0 0;
        position: relative;
        z-index: 1;
    }

    &__share-links {
        @include clearfix();
        color: $light;
        float: right;
        margin: rem-calc(14) 0 0;
        position: relative;
        z-index: 1;

        .header__share-links-label {
            @extend .tiny-text;
            float: left;
            line-height: rem-calc(25);
            margin-right: rem-calc(25);
        }

        .header__share-links-icons {
            a {
                float: left;

                &:not(:last-child) {
                    margin-right: rem-calc(25);
                }
            }
        }

        .header__share-links-toggle {
            display: none;
        }

        @include media-breakpoint-down(xs) {
            position: relative;

            .header__share-links-label {
                display: none;
            }

            .header__share-links-toggle {
                @include rem-size(25);
                @include icon('icon-share.svg');
                cursor: pointer;
                display: inline-block;
            }

            .header__share-links-icons {
                @include animate(opacity);
                display: block;
                opacity: 0;
                position: absolute;
                top: rem-calc(39);
                right: 0;
                z-index: -1;

                @at-root .header--fixed & {
                    top: rem-calc(44);
                }

                &--open {
                    opacity: 1;
                    z-index: 10;
                }

                a {
                    display: block;
                    float: none;
                    box-shadow: 0 0 rem-calc(10) rgba(0, 0, 0, 0.5);
                    margin: 0 0 rem-calc(10) !important;
                }
            }
        }
    }

    &__menu {
        a {
            @include animated-underline();
            color: $white;
        }
    }

    &__menu-items--level-2 {
        display: none;

        .header__menu-item > a {
            @include menu-item-arrow();
        }
    }

    &__menu-item {
        > a {
            z-index: 1;
        }

        &--active,
        &--open {
            > a:after {
                left: rem-calc(-4);
                right: rem-calc(-4);
            }
        }
    }

    &__menu-item--open .header__menu-items {
        display: block;
    }

    &__menu-toggle {
        @include text-hide();
        display: block;
        height: rem-calc(50);
        outline: none;
        padding: rem-calc(16) 5%;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(10% + #{rem-calc(22)});
        z-index: 1;

        span {
            @include animate(transform width opacity span top);
            background: $white;
            display: block;
            height: rem-calc(2);
            position: absolute;
            width: rem-calc(22);

            &:first-child {
                top: rem-calc(16);
            }

            &:nth-child(2) {
                top: rem-calc(24);
            }

            &:last-child {
                top: rem-calc(32);
            }
        }

        &--open {
            span:first-child {
                transform: rotate(45deg);
                top: rem-calc(23.5);
            }

            span:nth-child(2) {
                width: 0;
                opacity: 0;
            }

            span:last-child {
                transform: rotate(-45deg);
                top: rem-calc(23.5);
            }
        }
    }

    &__language-switcher {
        position: relative;
        z-index: 1;

        a {
            color: $white;
        }
    }

    &__trial-link,
    &__login-link {
        @extend .button;
        float: right;
        position: relative;
        z-index: 1;
    }

    &:not(&--fixed) {
        .header__trial-link,
        .header__login-link {
            @extend .button--transparent;
        }
    }

    &--fixed {
        background: $white;
        border-bottom: rem-calc(1) solid #a5a4ae;
        position: fixed;

        &:before {
            background: $white;
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }

        .header__logo {
            @include icon('logo-trendmanager-black.svg');
        }

        .header__menu a {
            color: $body-color;
        }

        .header__menu-toggle {
            span {
                background: $body-color;
            }
        }

        .header__trial-link {
            @extend .button--primary;
        }

        .header__login-link {
            @extend .button--lighter;
        }

        .header__language-switcher a {
            color: $body-color;
        }
    }

    @include media-breakpoint-down(lg) {
        &__menu-container {
            @include animate(transform);
            background: $white;
            bottom: 0;
            display: block;
            left: 0;
            position: fixed;
            right: 0;
            top: rem-calc(50);
            transform: translateY(-120%);

            &--open {
                transform: translateY(0);
            }
        }

        &__menu {
            @include animate(transform);
            bottom: rem-calc(51);
            font-size: rem-calc(22);
            line-height: rem-calc(28);
            overflow-x: hidden;
            overflow-y: auto;
            position: absolute;
            top: rem-calc(50);
            width: 200%;

            ul {
                margin-top: rem-calc(75);
                padding: 0 2.5%;
            }

            li:not(:last-child) {
                margin-bottom: rem-calc(32);
            }

            &--show-sub-menu {
                transform: translateX(-50%);
            }
        }

        &__menu-item > a {
            @include menu-item-arrow();
        }

        &__menu-items--level-2 {
            position: absolute;
            left: 50%;
            top: 0;
        }

        &__menu-back-link {
            font-size: rem-calc(16);
            font-weight: 700;
            line-height: rem-calc(21);
            left: 52.5%;
            position: absolute;
            top: rem-calc(20);
        }

        &__trial-link,
        &__login-link {
            border-bottom: rem-calc(1) solid #a5a4ae !important;
            position: absolute;
            top: 0;
        }

        &__trial-link {
            width: 66%;
        }

        &__login-link {
            width: 34%;
            left: 66%;
        }

        &__language-switcher {
            border-top: rem-calc(1) solid $divider;
            bottom: 0;
            padding-left: 50%;
            position: absolute;
            width: 100%;

            ul {
                float: left;
                transform: translateX(-50%);
            }

            li {
                line-height: rem-calc(50);
            }

            a {
                padding: 0 rem-calc(10);
            }
        }
    }

    @include media-breakpoint-up(xl) {
        height: rem-calc(80);

        &__logo {
            @include rem-size(225, 20);
            margin: rem-calc(30) rem-calc(60) 0 0;
        }

        &__share-links {
            margin-top: rem-calc(27);
        }

        &__menu {
            float: left;
            margin: rem-calc(30) 0 0;

            > ul {
                @include clearfix();

                > li {
                    float: left;
                    line-height: rem-calc(19);

                    &:not(:last-child) {
                        margin-right: rem-calc(30);
                    }
                }
            }
        }

        &__menu-items--level-2 {
            @include animate(transform);
            background: $dark;
            bottom: 0;
            left: 0;
            overflow: auto;
            padding: rem-calc(75) 5%;
            position: fixed;
            right: 0;
            top: rem-calc(80);
            transform: translateY(calc(-100% - #{rem-calc(80)}));

            li {
                font-size: rem-calc(43);
                line-height: rem-calc(55);

                &:not(:last-child) {
                    margin-bottom: rem-calc(50);
                }
            }

            a:after {
                height: rem-calc(3);
                bottom: rem-calc(-5);
            }
        }

        &__menu-item--open &__menu-items--level-2 {
            transform: translateY(0);
        }

        &__menu-back-link {
            display: none;
        }

        &__menu-toggle {
            display: none;
        }

        &__trial-link,
        &__login-link {
            margin: rem-calc(15) 0;
        }

        &__trial-link {
            margin-right: rem-calc(10);
        }

        &__login-link {
            margin-right: rem-calc(20);
        }

        &__language-switcher {
            float: right;
            margin: rem-calc(30) 0 0;
        }

        &:not(&--fixed) {
            .header__trial-link {
                display: none;
            }
        }
    }
}


// CUSTOM BREAKPOINTS
@media screen and (max-width: 1244px) {
    .header__menu > ul > li:not(:last-child) {margin-right: rem-calc(16)}
}
