/**
 * Landingpage
 * -------------------------
 */

.landingpage {
    background: $dark-body-bg;

    .container {
        background: $body-bg;
        margin: 0 auto;
        max-width: rem-calc(1440);
        position: relative;
    }

    @media (min-width: rem-calc(1440)) {
        .header--fixed {
            left: 50%;
            right: -50%;
            max-width: rem-calc(1440);
            // The element is fixed -> we can't use a percent-value for the padding anymore,
            // because it's relative to the viewport and not relative to the width of the container
            padding: 0 rem-calc(1440*0.05);
            transform: translateX(-50%);
        }
    }
}
