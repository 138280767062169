/**
 * Tiles
 * -------------------------
 */

.ce-tiles {
    &__share-links {
        @include clearfix();
        float: right;
        padding: rem-calc(16) 5%;

        span, a {
            float: left;

            &:not(:last-child) {
                margin-right: rem-calc(25);
            }
        }

        span {
            @extend .tiny-text;
            line-height: rem-calc(25);
        }
    }

    &__column {
        clear: both;
    }

    @include media-breakpoint-up(lg) {
        display: table;
        margin: rem-calc(-25) 0;
        position: relative;
        width: 100%;

        &:first-child {
            margin-top: rem-calc(25);
        }

        &__column {
            display: table-cell;
            padding-bottom: 50%;
            position: relative;
            width: 50%;
            z-index: 1;

            &:nth-last-child(even) {
                top: rem-calc(-25);
            }

            &:nth-last-child(odd) {
                bottom: rem-calc(-25);
            }
        }

        &__share-links {
            padding: 0;
            position: absolute;
            right: 5%;
            top: rem-calc(-12);
        }

        & + & {
            margin-top: rem-calc(25);
        }

        &--condensed &__column {
            padding-bottom: 33.33333%;
        }
    }
}