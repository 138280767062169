/**
 * Slider
 * -------------------------
 */

.slider {
  position: relative;

  .tns-nav {
    bottom: rem-calc(20);
    left: rem-calc(20);
    justify-content: center;
    position: absolute;
    right: rem-calc(20);
    top: auto;
  }

  &__controls {
    outline: none;

    .button {
      position: absolute;
      z-index: 1;

      &--next,
      &--prev {
        &:after {
          @include rem-size(50);
        }
      }

      &[disabled] {
        opacity: 0;
      }
    }

    &--static {
      height: rem-calc(40);
      margin: rem-calc(40) auto;
      position: relative;
      width: rem-calc(120);

      .button {
        top: 0 !important;

        &:first-child {
          left: 0 !important;
        }

        &:last-child {
          right: 0 !important;
        }
      }
    }
  }

  &__items {
    align-items: center;
    display: flex;

    &--align-top {
      align-items: flex-start;
    }

    &--stretch {
      align-items: stretch;
    }
  }

  &__item {
    flex: 0 0 auto;
  }

  @include media-breakpoint-down(sm) {
    &__controls {
      .button {
        top: 0;

        &:first-child {
          left: 0;
        }

        &:last-child {
          right: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__controls {
      .button {
        top: calc(50% - #{rem-calc(25)});

        &:first-child {
          left: rem-calc(-50);
        }

        &:last-child {
          right: rem-calc(-50);
        }
      }
    }
  }
}
