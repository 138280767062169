/**
 * Image teaser
 * -------------------------
 */

.ce-image-teaser {
    @include color-variants();
    @include gradient-variants();
    @include gradient-hover-variants();
    background: no-repeat center;
    background-size: cover;
    display: table;
    height: rem-calc(250);
    position: relative;
    width: 100%;

    &__title {
        color: inherit;
        display: table-cell;
        font-size: rem-calc(19);
        font-weight: 700;
        position: relative;
        text-align: center;
        vertical-align: middle;

        @include hover-focus() {
            color: inherit;
        }
    }

    @include media-breakpoint-up(md) {
        height: 100vh;
    }
}