/**
 * Newsletter form
 * -------------------------
 */

.ce-newsletter-form {
    padding: rem-calc(100) rem-calc(15);
    text-align: center;

    &__headline,
    &__subline {
        margin: 0 auto;
        max-width: rem-calc(500);
        width: 85%;
    }

    &__subline {
        margin-top: rem-calc(10);
    }

    .form {
        margin: rem-calc(40) auto 0;
        max-width: rem-calc(1140);
        width: 80%;

        &__field-wrapper {
            margin: 0 auto rem-calc(40);
            max-width: rem-calc(720);
            text-align: left;
            width: 90%;
        }

        .parsley-errors-list {
            color: $error-color;
        }
    }

    .selectable-tags {
        margin-bottom: rem-calc(20);
    }

    @include media-breakpoint-up(md) {
        padding: rem-calc(200) rem-calc(115);
    }
}