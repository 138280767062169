/**
 * Off-Center-Text
 * -------------------------
 */

.ce-off-center-text {
    @include color-variants();

    &__inner {
        @include background-variants();
        @include color-variants();
    }

    &__content {
        padding: 10vw 5vw;
    }

    &__headline {
        margin-bottom: rem-calc(20);
    }

    &__image {
        img {
            @extend .img-fluid;
        }
    }

    @include media-breakpoint-up(sm) {
        &__content {
            max-width: rem-calc(600);
            margin: 0 auto;
            padding-left: 0;
            padding-right: 0;
            width: 85%;
        }

        &__image {
            max-width: rem-calc(600);
            margin: -10vw auto 0;
            position: relative;
            top: 5vw;
            width: 85%;
            z-index: 1;

            img {
                max-width: rem-calc(440);
                width: 100%;
            }
        }

        &--image-bottom {
            .ce-off-center-text__image {
                text-align: right;
            }
        }

        &--image-middle {
            .ce-off-center-text__image {
                text-align: center;
            }
        }
    }

    @include media-breakpoint-only(lg) {
        &__content {
            width: 75%;
        }
    }

    @include media-breakpoint-up(lg) {
        & + & {
            margin-top: rem-calc(-50);
        }

        &__inner {
            position: relative;
            width: percentage(2/3);
        }

        &__image {
            margin: 0;
            max-width: rem-calc(440);
            position: absolute;
            width: 50%;
        }

        &--left {
            .ce-off-center-text__image {
                right: 0;
                transform: translateX(calc(100% - #{rem-calc(50)}));
            }
        }

        &--right {
            .ce-off-center-text__inner {
                margin-left: percentage(1/3);
            }

            .ce-off-center-text__image {
                left: 0;
                transform: translateX(calc(-100% + #{rem-calc(50)}));
            }
        }

        &--image-top {
            .ce-off-center-text__image {
                top: 10vw;
            }
        }

        &--image-middle {
            .ce-off-center-text__image {
                top: 50%;
            }

            &.ce-off-center-text--left {
                .ce-off-center-text__image {
                    transform: translate(calc(100% - #{rem-calc(50)}), -50%);
                }
            }

            &.ce-off-center-text--right {
                .ce-off-center-text__image {
                    transform: translate(calc(-100% + #{rem-calc(50)}), -50%);
                }
            }
        }

        &--image-bottom {
            .ce-off-center-text__image {
                bottom: 10vw;
            }
        }
    }
}
