/**
 * Video
 * -------------------------
 */

.ce-video {
    padding: rem-calc(50) 5%;

    &__content {
        margin: 0 auto rem-calc(40);
        max-width: rem-calc(785);
        text-align: center;

        h2:not(:last-child) {
            margin-bottom: rem-calc(10);
        }
    }

    &__video {
        max-width: rem-calc(1900);
        margin: 0 auto;

        &--small {
            max-width: rem-calc(1000);
        }
    }

    &__link {
        margin-top: rem-calc(40);
        text-align: center;
    }

    @include media-breakpoint-up(md) {
        &__content {
            margin-bottom: rem-calc(80);
        }

        &__link {
            margin-top: rem-calc(60);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(100);
        padding-top: rem-calc(100);
    }
}
