/**
 * Styling for RTE-editor
 * --------------------
 */

.rte {
    a {
        border-bottom: rem-calc(2) solid $brand-secondary;
        color: $brand-secondary;

        @include hover-focus() {
            background: $brand-secondary;
            color: $white;
        }
    }

    ul, ol {
        margin: 0;
    }

    ul.checklist {
        padding-left: 0;

        li {
            background: url('#{$image-base-uri}icon-check.svg') no-repeat 0 rem-calc(4);
            padding-left: rem-calc(26);

            &:not(:last-child) {
                margin-bottom: rem-calc(20);
            }

            &:before {
                display: none;
            }
        }
    }

    p:not(:last-child),
    ul:not(:last-child),
    ol:not(:last-child) {
        margin-bottom: rem-calc(24);
    }

    h2, .h2,
    h3, .h3 {
        margin: rem-calc(40) 0 rem-calc(20);
    }

    img {
        @include img-fluid();
    }

    .marked {
        background-color: $brand-secondary;
        color: $white;
    }

    @include media-breakpoint-up(sm) {
        h2, .h2,
        h3, .h3 {
            margin-top: rem-calc(60);
        }
    }
}

.click-to-enhance {
    &__content {
        padding-top: rem-calc(20);
    }

    &__toggle {
        @extend .button;
        @extend .button--primary;
        background-image: none !important;
        display: block;
        margin-top: rem-calc(20);
        position: relative;
        width: 100%;

        span {
            align-items: center;
            display: inline-flex;
            position: relative;

            &:after {
                @include rem-size(25);
                background: url('#{$image-base-uri}icon-chevron-down.svg') no-repeat center;
                background-size: contain;
                content: '';
                display: inline-block;
                position: relative;
                margin-left: rem-calc(6);
                top: rem-calc(3);
                transform: rotate(180deg);
            }
        }

        &--show {
            span:after {
                transform: rotate(0deg);
                top: rem-calc(0);
            }
        }
    }
}
