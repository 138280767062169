/**
 * Footer
 * -------------------------
 */

.footer {
    overflow-x: hidden;

    &__slogan {
        @extend .clearfix;
        @extend .tiny-text;
        background: $white;
        padding: rem-calc(18) 5%;
        text-align: right;

        &:before {
            @include icon('logo-trendone-black.svg');
            @include rem-size(113, 15);
            content: '';
            display: block;
            float: left;
            margin: rem-calc(8) $grid-gutter-width-base 0 0;
        }
    }

    &__columns {
        @include make-row();
        padding: rem-calc(60) 5%;
    }

    &__column {
        @include make-col-ready();

        strong {
            display: block;
            margin-bottom: rem-calc(30);

            a {
                @extend %unstyled-link;
            }
        }
    }

    &__social-media {
        @extend .clearfix;
        @extend .tiny-text;
        margin-top: rem-calc(60);

        .social-media-icon {
            float: left;
            margin: rem-calc(15) rem-calc(15) 0 0;
        }
    }

    &__menu {
        li:not(:last-child) {
            margin: 0 0 rem-calc(30);
        }

        a {
            @include animated-underline($body-color);
            color: $body-color;
            font-weight: 700;
        }
    }

    &__hotline {
        @extend .h3;
        white-space: nowrap;
    }

    &__newsletter {
        margin-top: rem-calc(80);

        strong {
            margin: 0 0 rem-calc(10);
        }

        .button {
            margin: rem-calc(20) 0 0;
        }
    }

    @include media-breakpoint-down(md) {
        &__column:not(:last-child) {
            margin-bottom: rem-calc(30);
            padding-bottom: rem-calc(30);
            position: relative;

            &:after {
                background: $light-divider;
                bottom: 0;
                content: '';
                display: block;
                height: rem-calc(1);
                position: absolute;
                left: $grid-gutter-width-base / 2;
                right: $grid-gutter-width-base / 2;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__slogan {
            padding-bottom: rem-calc(43);
            padding-top: rem-calc(43);
        }

        &__columns {
            padding-bottom: rem-calc(100);
            padding-top: rem-calc(100);
        }

        &__column {
            @include make-col(1, 4);
        }
    }
}