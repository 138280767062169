/**
 * Text with image
 * -------------------------
 */

.ce-text-image {
    @include color-variants();
    @include background-variants();
    padding: rem-calc(50) 0;

    &__headline {
        margin: 0 auto;
        max-width: rem-calc(640);
        text-align: center;
        width: 85%;
    }

    &__body {
        margin: rem-calc(20) auto 0;
        max-width: rem-calc(500);
        width: 90%;
    }

    &__image img {
        @extend .img-fluid;
    }

    &--image-bottom {
        .ce-text-image__image {
            margin: rem-calc(30) rem-calc(16) 0;
            max-width: rem-calc(800);
        }
    }

    &--image-right {
        .ce-text-image__image {
            &:before {
                content: '';
                float: right;
                height: rem-calc(96);
                width: 0;
            }

            figure {
                clear: right;
                float: right;
                margin: rem-calc(30) 0 rem-calc(30) rem-calc(30);
                width: 50%;
            }

        }
    }

    & + & {
        padding-top: 0;
        margin-top: rem-calc(-20);
    }

    @include media-breakpoint-up(md) {
        &--image-bottom {
            .ce-text-image__image {
                margin: rem-calc(30) auto 0;
                width: 85%;
            }
        }

        &--image-right .ce-text-image__image figure {
            margin-right: rem-calc(-110);
            width: rem-calc(280);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(150);
        padding-top: rem-calc(150);

        & + & {
            margin-top: rem-calc(-120);
        }
    }
}