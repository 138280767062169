/**
 * Teaser row
 * -------------------------
 */

.ce-teaser-row {
    background: $light;

    &__headline {
        margin: 0 auto rem-calc(20);
        max-width: rem-calc(800);
        text-align: center;
        width: 85%;
    }

    &--wallpaper {
        .ce-teaser-row__teaser {
            @include color-variants();
            @include gradient-variants();
            @include gradient-hover-variants();
            background: no-repeat center;
            background-size: cover;
            position: relative;
            width: 100%;

            a {
                color: inherit;
                display: block;
                padding-bottom: 100%;
                position: relative;
            }
        }

        .ce-teaser-row__teaser-title {
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }

    &--image-with-text {
        padding: rem-calc(50) 5% rem-calc(30);

        .ce-teaser-row__teaser {
            text-align: center;
            margin-bottom: rem-calc(20);
        }

        .ce-teaser-row__teaser-inner {
            display: flex;
            height: 100%;
            flex-direction: column;
        }

        .ce-teaser-row__teaser-image {
            flex: 0 0 auto;
            margin-bottom: rem-calc(20);

            img {
                @include img-fluid();
            }
        }

        .ce-teaser-row__teaser-content {
          flex: 1 0 auto;
        }

        .ce-teaser-row__teaser-title:not(:last-child) {
            margin-bottom: rem-calc(10);
        }

        .ce-teaser-row__teaser-button {
            flex: 0 0 auto;
            margin-top: rem-calc(20);
        }
    }

    @include media-breakpoint-up(sm) {
        &--image-with-text {
            .ce-teaser-row__teasers {
                @include make-row();
            }

            .ce-teaser-row__teaser {
                @include make-col-ready();
                @include make-col(1, 2);
            }
        }
    }

    @include media-breakpoint-up(md) {
        &--wallpaper {
            .ce-teaser-row__teasers {
                display: table;
                width: 100%;
            }

            .ce-teaser-row__teaser {
                display: table-cell;
                width: 100%;
            }

            @for $i from 2 through 5 {
                .ce-teaser-row__teaser:nth-last-child(n+#{$i}),
                .ce-teaser-row__teaser:nth-last-child(n+#{$i}) ~ .ce-teaser-row__teaser {
                    width: 100%/$i;
                }
            }
        }

        &--image-with-text {
            .ce-teaser-row__teaser {
                @include make-col(1, 4);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__headline {
            margin-bottom: rem-calc(75);
        }

        &--image-with-text {
            padding-bottom: rem-calc(110);
            padding-top: rem-calc(130);

            .ce-teaser-row__teaser-image {
                margin-bottom: rem-calc(40);

                img {
                    @include img-fluid();
                }
            }
        }
    }
}