/**
 * Text-Image in tiles
 * -------------------------
 */

.ce-text-image-tiles {
    color: $white;
    margin: rem-calc(50) rem-calc(15);
    max-width: rem-calc(1900);

    &__image {
        background: no-repeat center;
        background-size: cover;
        padding-top: percentage(9/16);
    }

    &__content {
        @include background-variants();
        @include color-variants();
        padding: 10vw 5vw;
    }

    &__headline {
        margin-bottom: rem-calc(20);
    }

    @include media-breakpoint-up(sm) {
        &__content {
            padding-left: 0;
            padding-right: 0;
        }

        &__headline,
        &__body {
            margin-left: auto;
            margin-right: auto;
            max-width: rem-calc(600);
            width: 85%;
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
        margin-bottom: rem-calc(-25);
        margin-top: rem-calc(-25);
        position: relative;
        z-index: 1;

        &--margin-bottom {
            margin-bottom: rem-calc(200);
        }

        &--margin-top {
            margin-top: rem-calc(200);
        }

        &__image,
        &__content {
            flex: 1 0 auto;
            min-height: rem-calc(440);
            position: relative;
            width: 50%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 3.375vw;
            padding-top: 3.375vw;
        }

        &__image {
            padding-top: 0;
        }

        &--image-left {
            .ce-text-image-tiles__image {
                top: rem-calc(-25);
            }

            .ce-text-image-tiles__content {
                top: rem-calc(25);
            }
        }

        &--image-right {
            .ce-text-image-tiles__image {
                order: 1;
                top: rem-calc(25);
            }

            .ce-text-image-tiles__content {
                top: rem-calc(-25);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
}