/**
 * Mixins and functions
 * --------------------
 */


@function rem-calc($value){
    $remSize: $value / 16;
    @return #{$remSize}rem;
}

@mixin rem-size($width, $height: $width) {
    width: rem-calc($width);
    height: rem-calc($height);
}

@mixin icon($path, $width: 0, $height: 0, $position: 'left', $color: 'transparent') {
    background: #{$color} url('#{$image-base-uri}#{$path}') no-repeat #{$position};
    @if $width > 0 and $height > 0 {
        background-size: rem-calc($width) rem-calc($height);
    }
    @else {
        background-size: contain;
    }
}

@mixin animate($properties) {
    $value: ();
    @each $property in $properties {
        $value: append($value, $property 300ms, comma);
    }
    transition: $value
}

@mixin color-variants() {
    @each $name, $color in $font-colors {
        &--#{$name}-font {
            color: $color;

            h1, .h1 {
                color: inherit;
            }
        }
    }
}

@mixin background-variants() {
    @each $name, $color in $background-colors {
        &--#{$name}-background {
            background-color: $color;
        }
    }

    &--image-background {
        background: no-repeat center;
        background-size: cover;
    }
}

@mixin gradient-variants() {
    @each $name, $gradient in $background-gradients {
        &--#{$name}-gradient {
            &:before {
                background: $gradient;
                bottom: 0;
                content: '';
                left: 0;
                opacity: 0.65;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

@mixin gradient-hover-variants() {
    @each $name, $gradient in $background-gradients {
        &--#{$name}-gradient {
            &:before {
                @include animate(opacity);
            }

            &:hover,
            &:focus {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
}

@mixin menu-item-arrow() {
    @include animate(padding);
    @include icon('icon-arrow-big-right.svg', 16, 14, 'right center');
    padding-right: rem-calc(26);

    @include hover-focus() {
        padding-right: rem-calc(36);
    }

    @include media-breakpoint-up(xl) {
        background-size: rem-calc(32) rem-calc(28);
        padding-right: rem-calc(62);

        @include hover-focus() {
            padding-right: rem-calc(82);
        }
    }
}

@mixin animated-underline($color: $white) {
    position: relative;

    &:after {
        @include animate(left right);
        content: '';
        display: block;
        position: absolute;
        background: $color;
        height: rem-calc(2);
        bottom: rem-calc(-4);
        left: 50%;
        right: 50%;
    }

    @include hover-focus() {
        &:after {
            left: rem-calc(-4);
            right: rem-calc(-4);
        }
    }
}
