/**
 * Iframe
 * -------------------------
 */

.ce-iframe {
    iframe {
        border: none;
        width: 100%;
    }
}
