/**
 * CTA Teaser
 * -------------------------
 */

.ce-cta-teaser {
    color: $white;
    margin: rem-calc(50) rem-calc(15);
    max-width: rem-calc(1900);

    &__column {
        background: $brand-secondary;
        background-size: cover;
        position: relative;

        &:first-child {
            padding-bottom: 75%;
        }

        &--with-gradient:before {
            background: map-get($background-gradients, blue);
            bottom: 0;
            content: '';
            left: 0;
            opacity: 0.65;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &__image-overlay {
        display: none;
    }

    &__text {
        padding: rem-calc(50) rem-calc(10);
        text-align: center;
    }

    &__content {
        @extend .h2;
        margin: 0 0 rem-calc(30);
    }

    @include media-breakpoint-up(md) {
        display: table;
        margin-bottom: rem-calc(125);
        margin-top: rem-calc(125);
        position: relative;
        width: calc(100% - #{rem-calc(30)});

        &__column {
            display: table-cell;
            padding-bottom: 37.5% !important;
            width: 50%;

            &:first-child {
                bottom: rem-calc(-25);
            }

            &:last-child {
                top: rem-calc(-25);
            }
        }

        &__image-overlay {
            bottom: rem-calc(30);
            display: block;
            left: rem-calc(50);
            position: absolute;
            right: rem-calc(50);
        }

        &__image-headline {
            font-size: rem-calc(25);
            letter-spacing: rem-calc(1);
        }

        &__text {
            left: 0;
            padding: 0 rem-calc(35);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    @include media-breakpoint-up(lg) {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
}