/**
 * Flexbox
 * -------------------------
 */

.ce-flexbox {
    padding-bottom: rem-calc(50);

    &__content {
        margin: 0 auto rem-calc(40);
        max-width: rem-calc(785);
        text-align: center;

        h2:not(:last-child) {
            margin-bottom: rem-calc(10);
        }
    }

    &__columns {
        margin-bottom: $grid-gutter-width-base * -1;
    }

    &__column {
        display: flex;
        flex-direction: column;
        margin-bottom: $grid-gutter-width-base;

        > *:not(:last-child) {
            margin-bottom: $grid-gutter-width-base;
        }
    }

    &__link {
        margin-top: rem-calc(40);
        text-align: center;
    }

    &--white-background {
        background: $white;
    }

    @include media-breakpoint-down(md) {
        &__column--hide-on-mobile {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            margin-bottom: rem-calc(80);
        }

        &__columns {
            @include make-row();
            align-items: stretch;
        }

        &__column {
            @include make-col-ready();

            &--width-lg {
                @include make-col(7);
            }

            &--width-md {
                @include make-col(5);
            }

            &--width-sm {
                @include make-col(4);
            }
        }

        &__link {
            margin-top: rem-calc(60);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(150);

        &__content {
            margin-bottom: rem-calc(120);
        }

        &__column {
            &--width-xl {
                @include make-col(10);
            }

            &--width-lg2 {
                @include make-col(9);
            }

            &--width-lg {
                @include make-col(6);
            }

            &--width-md {
                @include make-col(4);
            }

            &--width-sm {
                @include make-col(3);
            }

            &--spacing-left-sm {
                margin-left: percentage(1 / 12);
            }

            &--spacing-left-lg {
                margin-left: percentage(1 / 6);
            }

            &--spacing-right-sm {
                margin-right: percentage(1 / 12);
            }

            &--spacing-right-lg {
                margin-right: percentage(1 / 6);
            }
        }

        &__link {
            margin-top: rem-calc(80);
        }
    }
}
