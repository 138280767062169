/**
 * Image
 * -------------------------
 */

.ce-image {
    img {
        @extend .img-fluid;
        display: block;
        margin: 0 auto;
    }

    figure {
        clear: both;
    }

    figcaption {
        padding: 0 rem-calc(15);
    }

    &__share-links {
        @include clearfix();
        float: right;
        padding: rem-calc(16) 5%;

        span, a {
            float: left;

            &:not(:last-child) {
                margin-right: rem-calc(25);
            }
        }

        span {
            @extend .tiny-text;
            line-height: rem-calc(25);
        }
    }

    @include media-breakpoint-up(lg) {
        &__share-links {
            float: right;
            margin: 0 5% rem-calc(12) 0;
            padding: 0;
        }
    }
}