/**
 * Headline
 * -------------------------
 */

.ce-headline {
    padding: rem-calc(50) 0;
    text-align: center;

    &__headline {
        margin: 0 auto;
        max-width: rem-calc(1400);
        width: 70%;
    }

    &__subline {
        margin-top: rem-calc(10);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(150);
        padding-top: rem-calc(150);

        &__subline {
            margin-top: rem-calc(30);
        }
    }
}