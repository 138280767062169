/**
 * Tile-Teaser
 * -------------------------
 */

.ce-tile-teaser {
    background: no-repeat center;
    // background-size: 100%;
    padding-bottom: 56.25%;

    &__tile {
        @include color-variants();
        @include background-variants();
        padding: rem-calc(50) rem-calc(35) rem-calc(35);
        position: relative;
    }

    &__body:not(:first-child) {
        margin-top: rem-calc(10);
    }

    &__link {
        margin-top: rem-calc(30);
        text-align: center;
    }

    @include media-breakpoint-down(sm) {
        &__tile,
        &__body {
            text-align: center;
        }
    }

    @include media-breakpoint-up(md) {
        background-size: cover;
        min-height: rem-calc(700);
        padding-bottom: rem-calc(60);

        &__tile {
            width: 40%;
            padding-bottom: rem-calc(100);
        }

        &__body {
            margin-bottom: 0;

            &:not(:first-child) {
                margin-top: rem-calc(20);
            }
        }

        &__link {
            bottom: 0;
            position: absolute;
            right: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        &__tile {
            width: 25%;
        }
    }
}
