/**
 * Stage
 * -------------------------
 */

.ce-stage {
    @include color-variants();
    background: no-repeat center;
    background-size: cover;
    display: table;
    height: 100vh;
    position: relative;
    text-align: center;
    width: 100%;

    &--small {
        height: 50vh;
    }

    &--with-overlay:before {
        background: #080c5f;
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0.3;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__content {
        display: table-cell;
        padding: rem-calc(70) 0;
        position: relative;
        text-align: center;
        vertical-align: bottom;

        h1,
        &-subline {
            margin: 0 auto rem-calc(10);
            width: 90%;
        }

        h1 {
            font-size: rem-calc(30);
            font-weight: 700;
            line-height: rem-calc(35);
        }

        &-subline {
            font-size: rem-calc(16);
            line-height: rem-calc(24);
        }
    }

    &__link--glue-to-bottom {
        bottom: 0;
        left: 50%;
        margin: 0 !important;
        position: absolute;
        transform: translateX(-50%);
    }

    &__scroll-down {
        display: none;
    }

    @media (min-height: rem-calc(600)) {
        &__scroll-down {
            @include rem-size(50);
            @include icon('icon-chevron-down.svg');
            animation: 2s bounce infinite both;
            bottom: 10%;
            cursor: pointer;
            display: block;
            left: 50%;
            margin-left: rem-calc(-25);
            position: absolute;
        }
    }

    @include media-breakpoint-up(lg) {
        &__content {
            vertical-align: middle;

            h1 {
                font-size: rem-calc(60);
                line-height: rem-calc(70);
            }

            &-subline {
                font-size: rem-calc(22);
                line-height: rem-calc(34);
            }
        }

        &__link {
            margin-top: rem-calc(30);
        }
    }

    @include media-breakpoint-up(xl) {
        &__content {
            h1 {
                max-width: rem-calc(1800);
                width: 80%;
            }

            &-subline {
                max-width: rem-calc(1200);
                width: 50%;
            }
        }
    }
}
