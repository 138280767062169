/**
 * Tile
 * -------------------------
 */

.ce-tile {
    @include color-variants();
    @include background-variants();
    @include gradient-variants();
    @include gradient-hover-variants();
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__overline {
        display: inline-block;
        margin: 0 rem-calc(16);
        padding: rem-calc(16) 0 0;
    }

    &__inner {
        padding: 10vw 5vw;
        position: relative;
        width: 100%;
    }

    &__headline ~ &__body,
    &__headline ~ &__subline {
        margin-top: rem-calc(20);
    }

    &__link {
        margin-top: rem-calc(30);
    }

    &--teaser {
        .ce-tile__inner {
            text-align: center;
        }

        .ce-tile__link {
            margin-top: rem-calc(10);

            a {
                border-color: inherit;
                color: inherit;

                @include hover-focus() {
                    background: none;
                }
            }
        }
    }

    &--text {
        &.ce-tile--button-bottom-right {
            .ce-tile__link {
                bottom: 0;
                margin: 0;
                position: absolute;
                right: 0;
            }
        }

        &.ce-tile--text-align-center {
            text-align: center;
        }
    }

    &--video {
        figure {
            width: 100%;
        }
    }

    &--image-background {
        min-height: rem-calc(260);
    }

    @include media-breakpoint-down(md) {
        &--text {
            &.ce-tile--button-bottom-right {
                padding-bottom: rem-calc(50);
            }
        }
    }

    @include media-breakpoint-up(sm) {
        &--image-background {
            min-height: rem-calc(340);
        }
    }

    @include media-breakpoint-up(md) {
        &--image-background {
            min-height: rem-calc(496);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 100%;
        position: absolute;
        width: 100%;

        &:nth-last-child(n+4):nth-last-child(-n+4):first-child,
        &:nth-last-child(n+4):nth-last-child(-n+4):first-child ~ & {
            padding-bottom: 50%;
            width: 50%;

            &:nth-child(1) {
                 left: 0;
                 top: 0;
             }

            &:nth-child(2) {
                left: 50%;
                top: 0;
            }

            &:nth-child(3) {
                left: 0;
                bottom: 0;
            }

            &:nth-child(4) {
                left: 50%;
                bottom: 0;
            }

            h1, .h1 {
                font-size: rem-calc(28);
                line-height: rem-calc(36);
            }
        }

        @at-root .ce-tiles__column {
            &:first-child .ce-tile {
                &:nth-last-child(n+2):nth-last-child(-n+2):first-child,
                &:nth-last-child(n+2):nth-last-child(-n+2):first-child ~ .ce-tile {
                    padding-bottom: 50%;

                    &:nth-child(1) {
                        left: 0;
                        top: 0;
                    }

                    &:nth-child(2) {
                        left: 0;
                        bottom: 0;
                    }

                    h1, .h1 {
                        font-size: rem-calc(35);
                    }
                }

                &:nth-last-child(n+3):nth-last-child(-n+3):first-child,
                &:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ .ce-tile {
                    padding-bottom: 50%;

                    &:nth-child(1) {
                        left: 0;
                        top: 0;

                        h1, .h1 {
                            font-size: rem-calc(35);
                        }
                    }

                    &:nth-child(2) {
                        left: 0;
                        bottom: 0;
                        width: 50%;

                        h1, .h1 {
                            font-size: rem-calc(28);
                        }
                    }

                    &:nth-child(3) {
                        left: 50%;
                        bottom: 0;
                        width: 50%;

                        h1, .h1 {
                            font-size: rem-calc(28);
                        }
                    }
                }
            }

            &:last-child .ce-tile {
                &:nth-last-child(n+2):nth-last-child(-n+2):first-child,
                &:nth-last-child(n+2):nth-last-child(-n+2):first-child ~ .ce-tile {
                    width: 50%;

                    &:nth-child(1) {
                        left: 0;
                        top: 0;

                        h1, .h1 {
                            font-size: rem-calc(35);
                        }
                    }

                    &:nth-child(2) {
                        left: 50%;
                        top: 0;

                        h1, .h1 {
                            font-size: rem-calc(35);
                        }
                    }
                }

                &:nth-last-child(n+3):nth-last-child(-n+3):first-child,
                &:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ .ce-tile {
                    width: 50%;

                    &:nth-child(1) {
                        left: 0;
                        top: 0;

                        h1, .h1 {
                            font-size: rem-calc(35);
                        }
                    }

                    &:nth-child(2) {
                        left: 50%;
                        padding-bottom: 50%;
                        top: 0;

                        h1, .h1 {
                            font-size: rem-calc(28);
                        }
                    }

                    &:nth-child(3) {
                        left: 50%;
                        padding-bottom: 50%;
                        bottom: 0;

                        h1, .h1 {
                            font-size: rem-calc(28);
                        }
                    }
                }
            }
        }

        &__inner {
            left: 7.5%;
            padding: 0;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            width: 85%;
        }

        &__overline {
            left: 7.5%;
            margin: 0;
            padding: 0;
            position: absolute;
            top: rem-calc(14);
        }

        &__headline,
        &__subline,
        &__body,
        &__link {
            margin-left: auto;
            margin-right: auto;
            max-width: rem-calc(600);
        }

        &--teaser {
            .ce-tile__body {
                bottom: rem-calc(-20);
                max-height: 0;
                opacity: 0;
                position: relative;
                transition: all 0.4s ease-in-out;
            }

            @include hover-focus() {
                .ce-tile__body {
                    bottom: 0;
                    max-height: 400px;
                    opacity: 1;
                }
            }
        }

        &--image-background {
            min-height: 0;
        }

        @at-root .ce-tiles--condensed & {
            padding-bottom: 66.66666%;

            &:nth-last-child(n+4):nth-last-child(-n+4):first-child,
            &:nth-last-child(n+4):nth-last-child(-n+4):first-child ~ .ce-tile {
                padding-bottom: 33.33333%;
            }

            h1, .h1 {
                font-size: rem-calc(35);
            }
        }

        @at-root .ce-tiles--condensed .ce-tiles__column {
            &:first-child .ce-tile {
                &:nth-last-child(n+2):nth-last-child(-n+2):first-child,
                &:nth-last-child(n+2):nth-last-child(-n+2):first-child ~ .ce-tile,
                &:nth-last-child(n+3):nth-last-child(-n+3):first-child,
                &:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ .ce-tile {
                    padding-bottom: 33.33333%;
                }
            }

            &:last-child .ce-tile {
                &:nth-last-child(n+3):nth-last-child(-n+3):first-child,
                &:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ .ce-tile {
                    &:nth-child(2),
                    &:nth-child(3) {
                        padding-bottom: 33.33333%;
                    }
                }
            }
        }
    }
}
