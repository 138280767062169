/**
 * Animations
 * -------------------------
 */

[data-animate] {
    visibility: hidden;
}

.no-animations [data-animate] {
    visibility: visible;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(rem-calc(-10));
    }

    60% {
        transform: translateY(rem-calc(-5));
    }
}
