/**
 * Helpers
 * --------------------
 */

%unstyled-link {
    border: none !important;
    color: $body-color;

    @include hover-focus() {
        background: none !important;
    }
}

%unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
