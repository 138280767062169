/**
 * Pricing
 * -------------------------
 */

.ce-pricing {
    margin: 0 auto;
    max-width: rem-calc(1400);
    padding: rem-calc(50) rem-calc(15);

    h1, .h1 {
        margin: 0 auto rem-calc(30);
        max-width: rem-calc(800);
        text-align: center;
    }

    &__box {
        @include animate(box-shadow);
        background: $white;
        padding: rem-calc(20);
        text-align: center;

        @include hover-focus() {
            box-shadow: $box-shadow-hover;
        }
    }

    &__title {
        @extend .h3;
        margin-bottom: rem-calc(20);
    }

    &__subline {
        margin-top: rem-calc(-20);
        margin-bottom: rem-calc(20);
    }

    &__monthly-price {
        margin-bottom: rem-calc(5);

        span {
            @extend .h1;
            font-weight: 700;
        }
    }

    &__yearly-price {
        margin-bottom: rem-calc(20);
        opacity: 0.5;
    }

    &__buy-link {
        margin-bottom: rem-calc(16);
    }

    &__trial-link {
        margin-bottom: rem-calc(30);
    }

    &__features {
        @extend .checklist;
        margin-top: rem-calc(20);
    }

    @include media-breakpoint-down(sm) {
        &__plan {
            margin-bottom: rem-calc(30);
        }
    }

    @include media-breakpoint-up(md) {
        padding-bottom: rem-calc(150);
        padding-top: rem-calc(150);

        h1, .h1 {
            margin: 0 auto rem-calc(70);
        }

        &__plans {
            @include make-row();
        }

        &__plan {
            @include make-col-ready();
            @include make-col(1, 3);
        }

        &__title,
        &__yearly-price {
            margin-bottom: rem-calc(40);
        }

        &__subline {
            margin-top: rem-calc(-35);
            margin-bottom: rem-calc(24);
        }

        &__features {
            margin-left: rem-calc(30);
            margin-top: rem-calc(40);
        }
    }

    @include media-breakpoint-up(lg) {
        &__box {
            padding: rem-calc(40) rem-calc(40) rem-calc(80);
        }
    }
}