/**
 * Logo wall
 * -------------------------
 */

.ce-logo-wall {
    background: $light;
    padding: rem-calc(50) 0 rem-calc(30);

    &__headline {
        margin: 0 auto rem-calc(20);
        max-width: rem-calc(800);
        text-align: center;
        width: 85%;
    }

    &__logos {
        @include make-row();
        padding: 0 5%;
    }

    &__logo {
        @include make-col-ready();
        @include make-col(1, 2);
        margin-bottom: rem-calc(20);
        text-align: center;

        a {
            @extend %unstyled-link;
        }

        figure {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        img {
            @include img-fluid();
        }
    }

    @include media-breakpoint-up(sm) {
        &__logo {
            @include make-col(1, 3);
        }
    }

    @include media-breakpoint-up(md) {
        &__logo {
            @include make-col(1, 6);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(130);
        padding-top: rem-calc(150);

        &__headline {
            margin-bottom: rem-calc(75);
        }
    }
}
