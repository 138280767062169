/**
 * Form Intro
 * -------------------------
 */

.ce-form-intro {
    background: $brand-secondary;
    color: $white;
    padding: rem-calc(70) rem-calc(16) 0;

    &__headline {
        color: inherit;
        margin: 0 auto;
        max-width: rem-calc(1400);
        text-align: center;
        width: 70%;

        &:not(:last-child) {
            margin-bottom: rem-calc(24);
        }
    }

    &__body {
        @include clearfix();
        margin: 0 auto;
        max-width: rem-calc(600);
    }

    &__image {
        margin: 0 0 rem-calc(24);
    }

    @include media-breakpoint-up(sm) {
        &__image {
            float: right;
            margin: rem-calc(4) 0 rem-calc(10) rem-calc(30);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: rem-calc(200);

        &__headline:not(:last-child) {
            margin-bottom: rem-calc(70);
        }
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem-calc(230);
    }
}