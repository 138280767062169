/**
 * Fonts
 * -------------------------
 */

@font-face {
    font-family: 'DIN OT';
    src: url('#{$font-base-uri}dinot.eot');
    src: url('#{$font-base-uri}dinot.eot?#iefix') format('embedded-opentype'),
    url('#{$font-base-uri}dinot.woff2') format('woff2'),
    url('#{$font-base-uri}dinot.woff') format('woff'),
    url('#{$font-base-uri}dinot.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'DIN OT';
    src: url('#{$font-base-uri}dinot-bold.eot');
    src: url('#{$font-base-uri}dinot-bold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-base-uri}dinot-bold.woff2') format('woff2'),
    url('#{$font-base-uri}dinot-bold.woff') format('woff'),
    url('#{$font-base-uri}dinot-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}